import React from "react"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import ImageReveal from "../reveals/ImageReveal"

const ButtonGroup = ({ className = "", buttons = [] }) => {
  return (
    <>
      {buttons.length > 0 && (
        <div className={`${className} -mb-4`}>
          {buttons.map((button, index) => (
            <ImageReveal className="inline-block" key={index}>
              <Button
                className={`mb-4 ${index + 1 < buttons.length ? "mr-4" : ""}`}
                btnType={index === 0 ? "primary" : "secondary"}
                link={button.link}
                target={button?.target === true ? "_blank" : ""}
                key={index}
                externalIcon={button.external_icon}
                icon={button.icon}
                onClick={button.onClick}
              >
                {button.link_text}
              </Button>
            </ImageReveal>
          ))}
        </div>
      )}
    </>
  )
}

export default ButtonGroup
